import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AclGuardGuard } from './authentication/guard/acl-guard.guard';
import { UserProfileComponent } from './modules/user-profile/user-profile.component';

export const Approutes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: './authentication/authentication.module#AuthenticationModule',
      },
    ],
  },
  {
    path: 'module',
    component: FullComponent,
    children: [
      {
        path: '',
        loadChildren: './dashboards/dashboard.module#DashboardModule',
      },
      {
        path: 'org-profile',
        loadChildren: './modules/org-profile/org-profile.module#OrgProfileModule',
      },
      {
        path: 'sysConfig',
        loadChildren: './system-config/system-config.module#SystemConfigModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'sysConfig' },
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
      },
      {
        path: 'labelConfig',
        loadChildren: './modules/label-config/label-config.module#LabelConfigModule',
      },
      {
        path: 'bom',
        loadChildren: './modules/bom/bom.module#BOMModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/bom' },
      },
      {
        path: 'packing',
        loadChildren: './modules/packing/packing.module#PackingModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/packing' },
      },
      {
        path: 'dashboard',
        loadChildren: './dashboards/dashboard.module#DashboardModule',
      },
      {
        path: 'ph',
        loadChildren: './modules/ph/ph.module#PhModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/ph' },
      },
      {
        path: 'supporting-material',
        loadChildren: './modules/supporting-material/supporting-material.module#SupportingMaterialModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/supportingMaterial' },
      },
      {
        path: 'supporting-material-inward',
        loadChildren:
          './modules/supporting-material-inward/supporting-material-inward.module#SupportingMaterialInwardModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/sm-inward' },
      },
      {
        path: 'supporting-material-allocation',
        loadChildren:
          './modules/supporting-material-allocation/supporting-material-allocation.module#SupportingMaterialAllocationModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/supportingMaterialAllocation' },
      },
      {
        path: 'materialPropertyType',
        loadChildren: './modules/material-properties/material-properties.module#MaterialPropertiesModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'materialPropertyType' },
      },
      {
        path: 'productCodes',
        loadChildren: './modules/product-codes/product-codes.module#ProductCodeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'productCodeModule' },
      },
      {
        path: 'process',
        loadChildren: './modules/process/process.module#ProcessModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/process' },
      },
      {
        path: 'openingstock',
        loadChildren: './modules/opening-stock-upload/opening-stock-upload.module#OpeningStockUploadModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/itemStock' },
      },
      {
        path: 'stock-area',
        loadChildren: './modules/stock-area/stock-area.module#StockAreaModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockArea' },
      },
      {
        path: 'processType',
        loadChildren: './modules/process-type/process-type.module#ProcessTypeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'processType' },
      },
      {
        path: 'solubility',
        loadChildren: './modules/solubility/solubility.module#SolubilityModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/solubility' },
      },
      {
        path: 'stock-param4',
        loadChildren: './modules/stock-param/stock-param4/stock-param4.module#StockParam4Module',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockParam4' },
      },
      {
        path: 'stock-param5',
        loadChildren: './modules/stock-param/stock-param5/stock-param5.module#StockParam5Module',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockParam5' },
      },
      {
        path: 'stock-param6',
        loadChildren: './modules/stock-param/stock-param6/stock-param6.module#StockParam6Module',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockParam6' },
      },
      {
        path: 'stock-param7',
        loadChildren: './modules/stock-param/stock-param7/stock-param7.module#StockParam7Module',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockParam7' },
      },
      {
        path: 'stock-param8',
        loadChildren: './modules/stock-param/stock-param8/stock-param8.module#StockParam8Module',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockParam8' },
      },
      {
        path: 'stock-param9',
        loadChildren: './modules/stock-param/stock-param9/stock-param9.module#StockParam9Module',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockParam9' },
      },
      {
        path: 'customParameters',
        loadChildren: './modules/custom-parameters/custom-parameters.module#CustomParametersModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'customParam' },
      },
      {
        path: 'sales-order',
        loadChildren: './modules/sales-order/sales-order.module#SalesOrderModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/salesOrder' },
      },
      {
        path: 'moisture',
        loadChildren: './modules/moisture/moisture.module#MoistureModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/moisture' },
      },
      {
        path: 'funnelSource',
        loadChildren: './modules/funnel-source/funnel-source.module#FunnelSourceModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'funnelSource' },
      },
      {
        path: 'costHead',
        loadChildren: './modules/cost-head/cost-head.module#CostHeadModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/costHead' },
      },
      {
        path: 'uom',
        loadChildren: './modules/uom/uom.module#UomModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'uom' },
      },
      {
        path: 'segment',
        loadChildren: './modules/segment/segment.module#SegmentModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'segment' },
      },
      {
        path: 'purity',
        loadChildren: './modules/purity/purity.module#PurityModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/purity' },
      },
      {
        path: 'department',
        loadChildren: './modules/department/department.module#DepartmentModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/department' },
      },
      {
        path: 'zone',
        loadChildren: './modules/zone/zone.module#ZoneModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/zone' },
      },
      {
        path: 'transport',
        loadChildren: './modules/transport/transport.module#TransportModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/transport' },
      },
      {
        path: 'crmActivity',
        loadChildren: './modules/crm-activity/crm-activity.module#CrmActivityModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'crm/activity' },
      },
      {
        path: 'funnelSource',
        loadChildren: './modules/funnel-source/funnel-source.module#FunnelSourceModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'funnel/source' },
      },
      {
        path: 'Planning-Scheduling',
        loadChildren: './modules/scheduling/scheduling.module#SchedulingModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/planning_scheduling' },
      },
      {
        path: 'scheduling',
        loadChildren: './modules/scheduling/scheduling.module#SchedulingModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/scheduling' },
      },
      {
        path: 'funnel',
        loadChildren: './modules/funnel/funnel.module#FunnelModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'crm/funnel' },
      },
      {
        path: 'digitalplatform',
        loadChildren: './modules/digitalplatform/digitalplatform.module#DigitalplatformModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'crm/funnelindiamart' },
      },
      {
        path: 'quotation',
        loadChildren: './modules/quotation/quotation.module#QuotationModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'crm/quotation' },
      },
      {
        path: 'calendarView',
        loadChildren: './modules/calendar-view/calendar-view.module#CalendarViewModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'crm/calendarView' },
      },
      {
        path: 'funnelType',
        loadChildren: './modules/funnel-type/funnel-type.module#FunnelTypeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/funnelType' },
      },
      {
        path: 'funnelStage',
        loadChildren: './modules/funnel-stage/funnel-stage.module#FunnelStageModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'crm/funnelStage' },
      },
      {
        path: 'party',
        loadChildren: './modules/party/party.module#PartyModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'party' },
      },
      {
        path: 'partytype',
        loadChildren: './modules/party-type/party-type.module#PartyTypeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'partyType' },
      },
      {
        path: 'categorytype',
        loadChildren: './modules/category-type/category-type.module#CategoryTypeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'categoryType' },
      },
      {
        path: 'purchase-order',
        loadChildren: './modules/purchase-order/purchase-order.module#PurchaseOrderModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/purchaseOrder' },
      },
      {
        path: 'product',
        loadChildren: './modules/product/product.module#ProductModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/item' },
      },
      {
        path: 'mould',
        loadChildren: './modules/mould/mould.module#MouldModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'mould' },
      },
      {
        path: 'packing-type-inward',
        loadChildren: './modules/packing-type-inward/packing-type-inward.module#PackingTypeInwardModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/packingTypeInward' },
      },
      {
        path: 'planning',
        loadChildren: './modules/planning/planning.module#PlanningModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/planning' },
      },
      {
        path: 'planning',
        loadChildren: './modules/planning/planning.module#PlanningModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/only_planning' },
      },
      {
        path: 'loom-vise-daily-production',
        loadChildren:
          './modules/loom-vise-daily-production-log/loom-vise-daily-production-log.module#LoomViseDailyProductionLogModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/loom-vise-daily-production-log' },
      },
      {
        path: 'productType',
        loadChildren: './modules/product-type/product-type.module#ProductTypeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/productType' },
      },
      {
        path: 'mouldName',
        loadChildren: './modules/mould-name/mould-name.module#MouldNameModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'mouldName' },
      },
      {
        path: 'mouldType',
        loadChildren: './modules/mould-type/mould-type.module#MouldTypeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'mouldType' },
      },
      {
        path: 'packaging',
        loadChildren: './modules/packaging/packaging.module#PackagingModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/packaging' },
      },
      {
        path: 'production-batch',
        loadChildren: './modules/production-batch/production-batch.module#ProductionBatchModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/productionbatch' },
      },
      {
        path: 'lot-wise-production-batch',
        loadChildren:
          './modules/lot-wise-production-batch/lot-wise-production-batch.module#LotWiseProductionBatchModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/lotwiseproductionbatch' },
      },
      {
        path: 'weavingProcess',
        loadChildren: './modules/weaving-process/weaving-process.module#WeavingProcessModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/weavingProcess' },
      },
      {
        path: 'slittingProcess',
        loadChildren: './modules/slitting-process/slitting-process.module#SlittingProcessModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/slittingProcess' },
      },
      {
        path: 'bagMakingProcess',
        loadChildren: './modules/bagmaking-process/bagmaking-process.module#BagmakingProcessModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/bagMakingProcess' },
      },
      {
        path: 'dailyProductionMachineWise',
        loadChildren:
          './modules/daily-production-machine-wise/daily-production-machine-wise.module#DailyProductionMachineWiseModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/dailyProductionMachineWise' },
      },
      {
        path: 'new-production-batch',
        loadChildren: './modules/new-production-batch/new-production-batch.module#NewProductionBatchModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/newproductionbatch' },
      },
      {
        path: 'daily-production',
        loadChildren: './modules/daily-production/daily-production.module#DailyProductionModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/dailyProduction' },
      },
      {
        path: 'inward',
        loadChildren: './modules/inward/inward.module#InwardModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/inward' },
      },
      {
        path: 'labTest',
        loadChildren: './modules/lab-test/lab-test.module#LabTestModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/labTest' },
      },
      {
        path: 'dispatch',
        loadChildren: './modules/dispatch/dispatch.module#DispatchModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/dispatch' },
      },
      {
        path: 'inward-foundary',
        loadChildren: './modules/inward-foundary/inward-foundary.module#InwardFoundaryModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/foundaryInward' },
      },
      {
        path: 'transferAndDispatch',
        loadChildren: './modules/transfer-and-dispatch/transfer-and-dispatch.module#TransferAndDispatchModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/transfer-and-dispatch' },
      },
      {
        path: 'pre-inward',
        loadChildren: './modules/pre-inward/pre-inward.module#PreInwardModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/preInward' },
      },
      {
        path: 'inward-stock',
        loadChildren: './modules/inward-stock/inward-stock.module#InwardStockModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/itemStock/stock' },
      },
      {
        path: 'materialRequest',
        loadChildren: './modules/material-request/material-request.module#MaterialRequestModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/requestApproval' },
      },
      {
        path: 'materialRequestConfig',
        loadChildren: './modules/material-request-config/material-request-config.module#MaterialRequestConfigModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/requestApprovalConfig' },
      },
      {
        path: 'stock-transfer',
        loadChildren: './modules/stock-transfer/stock-transfer.module#StockTransferModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/stockTransfer' },
      },
      {
        path: 'transfer-approval',
        loadChildren: './modules/transfer-approval/transfer-approval.module#TransferApprovalModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/transferApproval' },
      },
      {
        path: 'storeManagement',
        loadChildren: './modules/store-management/store-management.module#StoreManagementModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/storeManagement/allocate' },
      },
      {
        path: 'reconciliation',
        loadChildren: './modules/reconciliation/reconciliation.module#ReconciliationModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/storeManagement/reconcile' },
      },
      {
        path: 'message-templates',
        loadChildren: './modules/message-template/message-template.module#MessageTemplateModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/smsTemplate' },
      },
      {
        path: 'print-templates',
        loadChildren: './modules/print-template/print-template.module#PrintTemplateModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/printTemplate' },
      },
      {
        path: 'part-code',
        loadChildren: './modules/part-code/part-code.module#PartCodeModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/partCodeConfig' },
      },
      {
        path: 'materialreconciliation',
        loadChildren: './modules/material-reconciliation/material-reconciliation.module#MaterialReconciliationModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/material-reconciliation' },
      },
      {
        path: 'user',
        loadChildren: './modules/user/user.module#UserModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'staff' },
      },
      {
        path: 'machine-asset-setting',
        loadChildren: './modules/machine-setting/machine-setting.module#MachineSettingModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'machineSetting' },
      },
      {
        path: 'machine-asset-management',
        loadChildren: './modules/machine-asset-management/machine-assets.module#MachineAssetsModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'MachineAssets' },
      },
      {
        path: 'machine-activities',
        loadChildren: './modules/machine-activities/machineActivities.module#MachineActivitiesModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: '/machineActivities' },
      },
      {
        path: 'role',
        loadChildren: './modules/role/role.module#RoleModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'role' },
      },
      {
        path: 'aclConfig',
        loadChildren: './modules/acl-config/acl-config.module#AclConfigModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'acl' },
      },
      {
        path: 'reports',
        loadChildren: './modules/report/report.module#ReportModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/report' },
      },
      {
        path: 'machine',
        loadChildren: './modules/machine/machine.module#MachineModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/machine' },
      },
      {
        path: 'custom-field',
        loadChildren: './modules/custom-field/custom-field.module#CustomFieldModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'customField' },
      },
      {
        path: 'custom-module-field',
        loadChildren: './modules/custom-module-field/custom-module-field.module#CustomModuleFieldModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'moduleCustomField' },
      },
      {
        path: 'services',
        loadChildren: './modules/services/services.module#ServicesModule',
        // canActivate:[AclGuardGuard],
        // data:{"moduleName":"chemical/services"}
      },
      {
        path: 'moduleFieldConfig',
        loadChildren: './modules/module-param-config/module-param-config.module#ModuleParamConfigModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/formConfig' },
      },
      {
        path: 'location',
        loadChildren: './modules/location/location.module#LocationModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'erp/location' },
      },
      {
        path: 'rack',
        loadChildren: './modules/rack/rack.module#RackModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/rack' },
      },
      {
        path: 'branch',
        loadChildren: './modules/branch/branch.module#BranchModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'erp/branch' },
      },
      {
        path: 'cbl-to-cbl',
        loadChildren: './modules/cbl-to-cbl/cbl-to-cbl.module#CblToCblModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'erp/cbl-to-cbl' },
      },
      {
        path: 'transaction-report',
        loadChildren: './modules/transaction-report/transaction-report.module#TransactionReportModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'transactionReport' },
      },
      {
        path: 'approval-configuration',
        loadChildren: './modules/approval-configuration/approval-configuration.module#ApprovalConfigurationModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'approvalConfiguration' },
      },
      {
        path: 'approval-generic',
        loadChildren: './modules/approval-generic/approval-generic.module#ApprovalGenericModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'approvalGeneric' },
      },
      {
        path: 'freight',
        loadChildren: './modules/freight/freight.module#FreightModule',
        canActivate: [AclGuardGuard],
        data: { moduleName: 'chemical/freight' },
      },
    ],
  },
];
